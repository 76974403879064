<template>
  <el-dialog top="10vh" append-to-body :close-on-click-modal="false" :before-close="close" title="添加商品" width="55%" :visible.sync="show">
    <el-form size="small" :inline="true" :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="80px">
      <el-form-item label="商品名称" prop="ItemName">
        <el-input v-model="ruleForm.ItemName" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
      </el-form-item>
      <el-form-item label="货物名称" prop="GoodsName">
        <el-input v-model="ruleForm.GoodsName" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
      </el-form-item>
      <el-form-item label="数量" prop="Amount">
        <el-input v-model="ruleForm.Amount" @change="computedAllMoney" type="number" size="small" autocomplete="off" style="width: 30rem"></el-input>
      </el-form-item>
      <el-form-item label="单价" prop="Price">
        <el-input v-model="ruleForm.Price" @change="computedAllMoney" size="small" autocomplete="off" style="width: 30rem"></el-input>
      </el-form-item>
      <el-form-item label="总价" prop="AllMoney">
        <el-input v-model="ruleForm.AllMoney" disabled size="small" autocomplete="off" style="width: 30rem"></el-input>
      </el-form-item>
      <el-form-item label="图片">
        <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :http-request="progressAvatar"
            :before-upload="beforeAvatarUpload">
          <img v-if="skuGoodsUrl" :src="skuGoodsUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: left">
      <el-button size="small" v-loading="loading" type="primary" @click="submitForm('ruleForm')">提交</el-button>
      <el-button size="small" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {uploadImage} from "@/api/system";
import { mapGetters } from "vuex"
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    itemName: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        ItemName: [
          { required: true, message: '请输入商品名称', trigger: 'blur' },
        ],
        GoodsName: [
          { required: true, message: '请输入货物名称', trigger: 'blur' },
        ],
        Amount: [
          { required: true, message: '请输入数量', trigger: 'blur' },
        ],
        Price: [
          { required: true, message: '请输入价格', trigger: 'blur' },
        ],
        AllMoney: [
          { required: true, message: '请输入总价', trigger: 'blur' },
        ],
      },
      productOptions: [],
      loading: false,
      skuGoodsUrl: ""
    }
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.initializeForm()
        }
      }
    }
  },
  methods: {
    initializeForm() {
      this.ruleForm = {}
      this.skuGoodsUrl = ""
      this.ruleForm.ItemName = this.itemName
    },
    computedAllMoney() {
      if (this.ruleForm.Price && this.ruleForm.Amount) {
        this.$set(this.ruleForm, "AllMoney", parseFloat(this.ruleForm.Amount) * parseFloat(this.ruleForm.Price))
      }
    },
    close() {
      this.$emit('close')
    },
    uploadFile() {
      return new Promise((resolve, reject) => {
        if (!this.file) {
          resolve(undefined)
        } else {
          const extension =  this.file.name.slice(this.file.name.lastIndexOf('.') + 1);
          const formData = new FormData();
          formData.append('file', this.file);
          formData.append('FileName', `${this.userInfo.Id}${this.ruleForm.GoodsName}.${extension}`);
          formData.append('Path', 'ShopeeImg');
          uploadImage(formData).then(response => {
            const { Code, Msg, Data } = response
            if (Code === 200) {
              resolve(Data.ServerPath)
            } else {
              reject(Msg)
            }
          })
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const ServerPath = await this.uploadFile()
          const data = {
            ItemName: this.ruleForm.ItemName,
            GoodsName: this.ruleForm.GoodsName,
            Amount: this.ruleForm.Amount,
            Price: this.ruleForm.Price,
            AllMoney: this.ruleForm.AllMoney,
            Img: ServerPath ? `${process.env.VUE_APP_IMG_URL}${ServerPath}` : "",
            SkuDetail: {},
            outBoundAmount: 0
          }
          this.$emit("handle-submit", data)
        }
      })
    },
    progressAvatar() {
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("图片超过大小！！");
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.skuGoodsUrl = e.target.result;
      };
      this.file = file
      return isLt2M;
    },
  }
}

</script>

<style scoped lang="scss">
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader {
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}
</style>