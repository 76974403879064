<template>
  <el-dialog top="5vh" append-to-body :close-on-click-modal="false" :before-close="close" :title="tempTxt[dialogStatus]" width="70%" :visible.sync="show">
    <div class="queryItems mb20">
      <div class="u-start-flex mr15">
        <div class="default-font">SKU编码：</div>
        <el-input v-model="query.SKUCode" size="mini" class="mr15" clearable style="width: 20rem" placeholder="SKU编码"></el-input>
      </div>
      <div class="u-start-flex mr15">
        <div class="default-font">商品品类：</div>
        <el-select size="mini" v-model="query.ProductId" clearable style="width: 20rem">
          <el-option
              v-for="item in productOption"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" class="" size="mini" icon="el-icon-search" @click="getData">查询</el-button>
    </div>
    <el-table @row-click="handleRowClick" size="mini" :data="listData" v-loading="loading" style="width: 100%" border>
      <el-table-column label="" width="80">
        <template slot-scope="scope">
          <div class="state-active" v-if="currentState === scope.row.Id">
            <el-image class="confirm-png" :src="require('@/static/images/cod/confirm.png')"></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="图片" width="64">
        <template slot-scope="scope">
          <el-image v-if="scope.row.ImagePath" class="column-img" :preview-src-list="[scope.row.ImagePath]" :src="scope.row.ImagePath"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="Name" show-overflow-tooltip></el-table-column>
      <el-table-column label="SKU编码" prop="SKUCode"></el-table-column>
      <el-table-column label="品类" prop="ProductName"></el-table-column>
      <el-table-column label="库存" prop="Quantity"></el-table-column>
      <el-table-column label="尺寸" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ `${scope.row.Length}CM*${scope.row.Width}CM*${scope.row.Height}CM` }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="mt20"
        background
        @size-change="handleSizeChange"
        @current-change="handleChange"
        :current-page="PageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="PageRows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
    <span slot="footer">
      <el-button size="small" type="primary" @click="submitState">确认绑定</el-button>
      <el-button size="small" @click="close">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {getCODProduct, getCodSKU} from "@/api/cod";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    dialogStatus: {
      type: String,
      default: "create"
    }
  },
  data() {
    return {
      loading: false,
      query: {},
      listData: [],
      productOption: [],
      total: 0,
      PageIndex: 1,
      PageRows: 10,
      currentState: undefined,
      tempTxt: {
        create: "绑定SKU",
        update: "换绑SKU"
      }
    }
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.resetTemp()
        }
      }
    }
  },
  mounted() {
    this.getCodProduct()
  },
  methods: {
    submitState() {
      if (!this.currentState) {
        this.$message.warning("请选择SKU")
        return;
      }
      this.$emit('handle-click-submit', this.currentState)
    },
    handleRowClick(row) {
      this.currentState = row.Id
    },
    getCodProduct() {
      const data = {
        PageIndex: 1,
        PageRows: 100,
      }
      getCODProduct(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.productOption = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    resetTemp() {
      this.PageIndex = 1
      this.getData()
    },
    handleSizeChange(val) {
      this.PageRows = val
      this.PageIndex = 1;
      this.getData()
    },
    handleChange(current) {
      this.PageIndex = current;
      this.getData()
    },
    getData() {
      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
        QueryCodeType: 14,
        QueryCodes: this.query.SKUCode ? [this.query.SKUCode] : undefined,
        ProductId: this.query.ProductId || undefined
      }
      this.loading = true
      getCodSKU(data).then(response => {
        this.currentState = undefined
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.total = Total
          this.listData = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    close() {
      this.$emit('close')
    }
  }
}

</script>

<style scoped lang="scss">
::v-deep .el-dialog__footer {
  text-align: left;
}
.queryItems {
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  align-items: center;
}
.column-img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}
.state-active {
  width: 60px;
  //height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .confirm-png {
    width: 32px;
    height: 22px;
  }
}
</style>