<template>
  <el-dialog top="5vh" :close-on-click-modal="false" :before-close="close" title="库存出货" width="75%" :visible.sync="show">
    <div class="goods-box">
      <el-button type="primary" size="small" @click="addGoods">添加商品</el-button>
      <el-table
          :data="listData"
          style="width: 100%"
      >
        <el-table-column label="商品" width="464px">
          <template slot-scope="scope">
            <div class="u-start-flex" style="align-items: flex-start">
              <el-image class="goods-img" :src="scope.row.Img"></el-image>
              <div style="width: 300px; height: 144px">
                <div class="title">{{ scope.row.ItemName }}</div>
                <div class="sub-title">{{ scope.row.GoodsName }}</div>
                <div class="u-start-flex">
                  <el-input-number size="mini" v-model="scope.row.Amount" :disabled="true"></el-input-number>
                  <div class="goods-price"><span>￥</span><span>{{ scope.row.Price }}</span></div>
                </div>
                <div class="priceText"><span class="symbol">￥</span>{{ scope.row.AllMoney }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="SKU" width="240px">
          <template slot-scope="scope">
            <div class="sku-box" style="height: 144px" v-if="scope.row.SkuId">
              <div class="u-start-flex title">
                <div class="mr10">{{ scope.row.SkuDetail.SKUCode }}</div>
                <div>{{ scope.row.SkuDetail.Name }}</div>
              </div>
              <div class="u-start-flex sub-title">
                <div class="mr10">{{ scope.row.SkuDetail.GoodsTypeName }}</div>
                <div>{{ scope.row.SkuDetail.ProductName }}</div>
              </div>
              <div class="sub-title">{{ `${scope.row.SkuDetail.Length}cm*${scope.row.SkuDetail.Width}cm*${scope.row.SkuDetail.Height}cm` }}</div>
              <div class="priceText">库存：{{ scope.row.SkuDetail.Quantity }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="出库数量" width="150px">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.outBoundAmount" size="small" label="出库数量"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="bindSku(scope.row.Id)" v-if="!scope.row.SkuId" size="mini" type="text">绑定SKU</el-button>
            <br v-if="!scope.row.SkuId">
            <el-button @click="removeGoods(scope)" size="mini" type="text">移除商品</el-button>
            <br>
            <el-button @click="unBindSku(scope)" v-if="scope.row.SkuId" size="mini" type="text">解绑SKU</el-button>
            <br v-if="scope.row.SkuId">
            <el-button @click="editSku(scope.row.Id)" v-if="scope.row.SkuId" size="mini" type="text">换绑SKU</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer" class="dialog-footer mt20" style="text-align: left">
      <el-button size="medium" v-loading="loading" type="primary" @click="submitForm">提交出货</el-button>
      <el-button size="medium" @click="close">取消</el-button>
    </div>
    <shopee-bind-sku @handle-click-submit="handleClickSubmit" :show="visible" :dialog-status="dialogStatus" @close="handleClose"></shopee-bind-sku>
    <add-item :show="addVisible" :item-name="ItemName" @handle-submit="handleSubmit" @close="handleAddDialogClose"></add-item>
  </el-dialog>
</template>

<script>
import {getCodSKU, unBindStock} from "@/api/cod";
import ShopeeBindSku from "@/views/member/shopee/components/ShopeeBindSku.vue";
import AddItem from "@/views/member/shopee/components/AddItem.vue";
import {boundStock} from "@/api/member";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    listData: {
      type: Array,
      default: () => []
    },
    OrderId: {
      type: Number,
      default: 0
    }
  },
  components: {
    ShopeeBindSku,
    AddItem
  },
  watch: {
    show: {
      handler(val) {
        if (val && this.listData.length) {
          this.autoBindSku()
        }
        if (val && this.QueryCodes.length) {
          this.getSku()
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      visible: false,
      dialogStatus: "",
      currentState: "",
      addVisible: false,
      loading: false
    }
  },
  computed: {
    ItemName() {
      if (this.listData.length) {
        return this.listData[0].ItemName
      } else {
        return ""
      }
    },
    QueryCodes() {
      return this.listData.filter(item => {
        return item.SkuId
      }).map(item => {
        return item.SkuId
      })
    }
  },
  methods: {
    removeGoods(scope) {
      this.$confirm(`该操作会移除名称为（${scope.row.GoodsName}）的商品，是否继续？`,
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: 'warning'
          }
      ).then(() => {
        const data = {
          OperationType: 1,
          Id: scope.row.Id || 0
        }
        unBindStock(data).then(response => {
          const { Code, Msg } = response
          if (Code === 200) {
            this.$message.success("移除成功！")
            const index = scope.$index
            this.listData.splice(index, 1)
          } else {
            this.$message.warning(Msg)
          }
        })
      })
    },
    handleSubmit(data) {
      this.listData.push(data)
      this.getSku()
      this.addVisible = false
    },
    addGoods() {
      this.addVisible = true
    },
    handleAddDialogClose() {
      this.addVisible = false
    },
    handleClickSubmit(val) {
      const i = this.listData.findIndex(item => {
        return item.Id === this.currentState
      })
      this.$set(this.listData[i], "SkuId", val)
      this.getSku()
      this.handleClose()
    },
    bindSku(rowId) {
      this.currentState = rowId
      this.dialogStatus = "create"
      this.visible = true
    },
    autoBindSku() {
      this.listData.forEach((item, i) => {
        if (item.Sku && !item.SkuId) {
          const data = {
            PageIndex:1,
            PageRows:10,
            QueryCodeType:14,
            QueryCodes:[item.Sku]
          }
          getCodSKU(data).then(response => {
            const { Data } = response
            if (Data.length) {
              this.$set(this.listData[i], "SkuId", Data[0].Id)
              item['SkuDetail'] = Data[0]
            }
          })
        }
      })
    },
    editSku(rowId) {
      this.currentState = rowId
      this.dialogStatus = "update"
      this.visible = true
    },
    unBindSku(scope) {
      const data = {
        OperationType: 0,
        Id: scope.row.Id || 0
      }
      unBindStock(data).then(response => {
        const { Code, Msg } = response
        if (Code === 200) {
          this.$message.success("移除成功！")
          const index = scope.$index
          this.$set(this.listData[index], "SkuId", undefined)
          this.getSku()
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    submitForm() {
      const data = {
        OrderId: this.OrderId,
        OrderItemDetailInfo: this.listData.map(item => {
          return {
            ...item,
            Amount: item.outBoundAmount
          }
        })
      }
      boundStock(data).then(response => {
        const { Code, Msg } = response
        if (Code === 200) {
          this.$message.success("出库成功！")
          this.$emit('refresh')
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    handleClose() {
      this.visible = false
    },
    close() {
      this.$emit('close')
    },
    getSku() {
      const data = {
        PageIndex: 1,
        PageRows: 99,
        QueryCodeType: 1,
        QueryCodes: this.QueryCodes
      }
      getCodSKU(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.listData.forEach(item => {
            if (item.SkuId) {
              item['SkuDetail'] = Data.filter(sku => {
                return item.SkuId === sku.Id
              })[0]
            }
          })
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  }
}

</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 10px 20px;
}
.goods-box {
  .title {
    color: #11192d;
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    //line-height: 30px;
  }
  .sub-title {
    border-radius: 8px;
    font-family: PingFang SC;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
    color: #ff5000;
  }
  .priceText {
    margin-top: 10px;
    display: inline-block;
    font-style: italic;
    margin-right: 2px;
    vertical-align: baseline;
    font-family: AlibabaSans102v1TaoBao-Bold;
    font-size: 20px;
    line-height: 20px;
    vertical-align: baseline;
    color: #ff5000;
    .symbol {
      font-size: 12px;
    }
  }
  .goods-price {
    border-radius: 20px;
    float: left;
    margin-left: 8px;
    padding: 0 10px;
    background-color: rgb(255, 80, 0);
    color: rgb(255, 255, 255);
  }
  .goods-img {
    width: 144px;
    height: 144px;
    border-radius: 10px;
    margin-right: 12px;
  }
}
</style>