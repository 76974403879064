<template>
  <el-drawer
      title="订单筛选"
      :visible.sync="drawer"
      :direction="direction"
      append-to-body
      :before-close="handleClose">
      <div class="query-boxes">
        <div class="query-items" v-for="item in listData" :key="item.field">
          <div class="title">{{ item.title }}</div>
          <div class="query-options">
            <div @click="onNav(item.field, nav.value)" :class="{ 'btn_active' : query[item.field] === nav.value }" class="option" v-for="nav in item.options" :key="nav.value">{{ nav.label }}</div>
          </div>
        </div>
        <div class="query-items">
          <div class="title">下单时间</div>
          <el-date-picker
              size="mini"
              v-model="query.dateRange"
              type="datetimerange"
              :range-separator="$t('order.separator')"
              :start-placeholder="$t('order.startTime')"
              :end-placeholder="$t('order.endTime')">
          </el-date-picker>
        </div>
      </div>
      <div class="query-btn">
        <el-button style="width: 40%" size="small" type="primary" @click="confirm">确定</el-button>
        <el-button style="width: 40%" size="small" type="info" @click="toReset">重置</el-button>
      </div>
  </el-drawer>
</template>

<script>

import {getShopeeList} from "@/api/member";

export default {
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    initialQuery: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    drawer: {
      handler(val) {
        if (val) {
          this.query = this.initialQuery
          this.$forceUpdate()
        }
      }
    }
  },
  data() {
    return {
      query: {
        dateRange: []
      },
      listData: [],
      direction: 'rtl',
    }
  },
  mounted() {
    this.getShopeeList()
    this.$store.dispatch('webSite/getCarrier').then(data => {
      this.listData.push({
        title: "承运商",
        field: "carrier",
        options: data.map(item => {
          return {
            label: item.Name,
            value: item.Id
          }
        })
      })
    })
    this.$store.dispatch('webSite/getWareHouse').then(data => {
      this.listData.push({
        title: "仓库",
        field: "warehouse",
        options: data.map(item => {
          return {
            label: item.Name,
            value: item.Id
          }
        })
      })
    })
    this.$store.dispatch('webSite/getCountry').then(data => {
      this.listData.push({
        title: "目的地",
        field: "destination",
        options: data.map(item => {
          return {
            label: item.Name,
            value: item.Id
          }
        })
      })
    })
    this.listData.push({
      title: "订单状态",
      field: "orderType",
      options: [
        {
          label: "未取消",
          value: 1
        },
        {
          label: "已取消",
          value: 2
        }
      ]
    })
  },
  methods: {
    toReset() {
      this.query = {
        dateRange: []
      }
    },
    confirm() {
      this.$emit("handle-confirm", this.query)
    },
    handleClose() {
      this.$emit('close')
    },
    onNav(field, val) {
      if (this.query[field] === val) {
        this.$set(this.query, field, undefined)
      } else {
        this.$set(this.query, field, val)
      }
    },
    getShopeeList() {
      const data = {
        PageIndex: 1,
        PageRows: 99
      }
      getShopeeList(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.listData.push({
            title: "店铺",
            field: "ElectronicShopId",
            options: Data.map(item => {
              return {
                label: item.ElectronicShopName,
                value: item.ElectronicShopId
              }
            })
          })
        } else {
          this.$message.warning(Msg)
        }
      })
    },
  }
}

</script>

<style scoped lang="scss">
::v-deep #el-drawer__title {
  font-size: 16px;
}
.query-btn {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.query-boxes {
  padding: 0 24px;
  margin-bottom: 1.5rem;
  height: calc(100vh - 96px - 32px - 2rem);
  overflow-y: auto;
  .query-items {
    padding: 16px 0;
    border-bottom: 1px solid #ebeef5;
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #1f2937;
      margin-bottom: 1rem;
    }
    .query-options {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      .option {
        cursor: pointer;
        font-size: 13px;
        padding: 6px 12px;
        border-radius: 6px;
        background: #f3f4f6;
        color: #4b5563;
        transition: all 0.2s ease;
        border: 1px solid transparent;
        &:hover {
          background: #e5e7eb;
          transform: translateY(-1px);
          box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
      }
      .btn_active {
        background: #10b981 !important;
        color: white;
        border-color: #059669;
        box-shadow: 0 2px 4px rgba(16,185,129,0.2);
      }
    }
  }
}

.query-btn {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  box-shadow: 0 -2px 6px rgba(0,0,0,0.05);
  
  .el-button {
    width: 45%;
    border-radius: 6px;
    
    &--primary {
      background: #10b981;
      border-color: #059669;
      
      &:hover {
        background: #059669;
        border-color: #047857;
      }
    }
  }
}

::v-deep .el-drawer__header {
  padding: 16px 24px;
  margin-bottom: 0;
  border-bottom: 1px solid #ebeef5;
  
  span {
    font-size: 16px;
    font-weight: 500;
    color: #1f2937;
  }
}

::v-deep .el-date-editor {
  width: 100%;
  margin-bottom: 10px;
  
  .el-range-separator {
    color: #6b7280;
  }
  
  .el-input__inner {
    border-radius: 6px;
    border-color: #e5e7eb;
    
    &:hover {
      border-color: #d1d5db;
    }
    
    &:focus {
      border-color: #10b981;
    }
  }
}
</style>