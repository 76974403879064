<template>
  <div class="address-container">
    <div class="address-title">{{ $t('address.title') }}</div>
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="item.label" :name="item.name" v-for="item in tabs" :key="item.id">
        <el-table
            :data="tableData"
            style="width: 100%; border: 0.1rem solid #ebeef5"
            :height="tableHeight"
            :max-height="tableHeight"
            size="mini"
            v-loading="loadingTable"
        >
          <el-table-column :label="$t('address.recipientname')" prop="RecipientName" align="center"></el-table-column>
          <el-table-column :label="$t('address.recipientphone')" prop="RecipientPhone" align="center"></el-table-column>
          <el-table-column :label="$t('address.recipientaddress')" prop="RecipientAddress" align="center"></el-table-column>
          <el-table-column :label="$t('address.TypeText')" prop="TypeText" align="center"></el-table-column>
          <el-table-column :label="$t('address.destinationname')" prop="DestinationName" align="center"></el-table-column>
          <el-table-column
              fixed="right"
              :label="$t('address.operate')"
              align="left"
              width="220">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.IsDefaultAddress ? $t('address.currentDefault') : $t('address.clickToSetDefault')" placement="top">
                <i 
                  :class="[
                    'el-icon-star-' + (scope.row.IsDefaultAddress ? 'on' : 'off'),
                    'default-star',
                    {'is-default': scope.row.IsDefaultAddress}
                  ]"
                  @click="handleSetDefault(scope.row)"
                ></i>
              </el-tooltip>
              <el-button type="text" size="small" @click="handleUpdate(scope.row)">
                {{ $t('address.edit') }}
              </el-button>
              <el-button type="text" size="small" @click="deleteAddress(scope.row.Id)">
                {{ $t('address.delete') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: right" class="mt20">
          <el-button icon="el-icon-circle-plus-outline" size="small" type="primary" class="merger-btn" @click="handleCreate">{{ $t('address.add') }}</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
    <address-operation
        ref="operation"
        @handle="operationSuccess"
        @close="closeVisible"
        :body="true"
        :show.sync="addressVisible"
        :dialog-status="dialogStatus"
        :temp="temp">
    </address-operation>
  </div>
</template>

<script>

import { defineComponent } from "vue";
import {getAddress, deleteAddress, setDefaultAddress} from "@/api/member"
import AddressOperation from "@/components/AddressOperation.vue";
import { mapGetters } from "vuex"
export default defineComponent({
  data() {
    return {
      tableData: [],
      dialogStatus: '',
      activeName: 'all',
      addressVisible: false,
      temp: {},
      loading: false,
      loadingForm: false,
      loadingTable: false
    }
  },
  components: {
    AddressOperation
  },
  computed: {
    ...mapGetters(['userInfo']),
    tableHeight() {
      return 'calc(100vh - 17.6rem - 147px)'
    },
    tabs() {
      return [
        {
          id: 1,
          name: 'all',
          label: this.$t('address.all')
        },
        {
          id: 2,
          name: 'district',
          label: this.$t('address.district')
        },
        {
          id: 3,
          name: 'nodistrict',
          label: this.$t('address.nodistrict')
        },
        {
          id: 4,
          name: 'dispatch',
          label: this.$t('address.dispatch')
        },
        {
          id: 5,
          name: 'pickup',
          label: this.$t('address.pickup')
        }
      ]
    } ,
    formData() {
      switch (this.activeName) {
        case "district":
          return { AddressNature: 1 }
        case 'nodistrict':
          return { AddressNature: 2 }
        case 'dispatch':
          return { Type: 1 }
        case 'pickup':
          return { Type: 2 }
        default:
          return {}
      }
    }
  },
  methods: {
    operationSuccess() {
      this.addressVisible = false
      this.getData()
    },
    closeVisible() {
      this.addressVisible = false
    },
    resetTemp() {
      this.temp = {
        DestinationId: "",
        ProvinceId: "",
        CityId: "",
        Type: 1,
        Area: "",
        RecipientName: "",
        RecipientPhone: "",
        RecipientAddress: ""
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.addressVisible = true
      this.$nextTick(() => {
        this.$refs.operation.clearValidate()
      })
    },
    async handleSetDefault(row) {
      if (row.IsDefaultAddress) {
        return // 如果已经是默认地址，不做处理
      }
      
      try {
        const data = {
          AddressId: row.Id,
          IsDefaultAddress: true,
          ClientId: this.userInfo.Id
        }
        
        await this.$confirm(
          this.$t('address.confirmSetDefault'),
          this.$t('address.common.prompt'),
          {
            type: 'info',
            confirmButtonText: this.$t('address.common.confirm'),
            cancelButtonText: this.$t('address.common.cancel')
          }
        )

        const response = await setDefaultAddress(data)
        if (response.Code === 200 && response.Success) {
          this.$message.success(this.$t('address.defaultSuccess'))
          await this.getData()
        } else {
          this.$message.warning(response.Msg)
        }
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error(this.$t('address.common.error'))
        }
      }
    },
    deleteAddress(rowId) {
      this.$confirm(this.$t('address.confirmDelete'), this.$t('address.common.prompt'), {
        confirmButtonText: this.$t('address.common.confirm'),
        cancelButtonText: this.$t('address.common.cancel'),
        type: 'warning'
      }).then(() => {
        deleteAddress([rowId]).then(response => {
          if (response.Success) {
            this.$message({
              type: 'success',
              message: this.$t('address.deleteSuccess')
            });
            this.getData();
          } else {
            this.$message({
              type: 'warning',
              message: response.Msg
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('domestic.cancelDelete')
        });
      });
    },
    handleClick() {
      this.addressVisible = false
      this.getData()
    },
    async handleUpdate(row) {
      this.loadingTable = true
      this.temp = await Object.assign({}, row)
      await this.$refs.operation.getCountry(row.Type === 2)
      await this.$refs.operation.fetchAddress('destination', row['DestinationId'])
      await this.$refs.operation.fetchAddress('province', row['ProvinceId'])
      await this.$refs.operation.fetchAddress('city', row['CityId'])
      if (row.Type === 2) await this.$refs.operation.fetchAddress('area', row['AreaId'])
      this.dialogStatus = 'update'
      this.addressVisible = true;
      this.loadingTable = false
      this.$nextTick(() => {
        this.$refs.operation.clearValidate()
      })
    },
    getData() {
      this.loadingTable = true
      getAddress(this.formData).then(response => {
        this.loadingTable = false
        const { Data } = response
        this.tableData = Data;
        this.$forceUpdate()
      }).catch(() => { this.loadingTable = false })
    }
  },
  created() {
    this.getData()
  }
})
</script>

<style scoped lang="scss">
.address-container {
  padding: 2rem 2.5rem;
  .address-title {
    padding: 1rem 2rem;
    background-color: #F0F4FB;
    color: #194D8F;
    font-size: 2rem;
  }
  .merger-btn {
    background-color: #194D8F;
    width: 16rem;
  }
}

.default-star {
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s;
  color: #909399;
  
  &:hover {
    transform: scale(1.2);
    color: #E6A23C;
  }
  
  &.is-default {
    color: #E6A23C;
  }
}

// 添加一个简单的动画效果
@keyframes star-pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.is-default {
  animation: star-pulse 1s ease-in-out;
}
</style>
